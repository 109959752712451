
import { Options, Vue } from "vue-class-component";
import Footer from "@/app/ui/components/footer/index.vue";

@Options({ components: { Footer } })
export default class Expired extends Vue {
  onHome() {
    this.$router.push("/");
  }
}
