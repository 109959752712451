
import { Options, Vue } from "vue-class-component";
import { AuthController } from "@/app/ui/controllers/AuthController";
import Footer from "@/app/ui/components/footer/index.vue";

@Options({
  components: {
    Footer
  }
})
export default class ForgotEmail extends Vue {
  form = {
    email: ""
  };
  showError = {
    email: false,
    isOpen: false
  };
  isOpen = false;
  emptyEmail = false;

  public get isLoading() {
    return AuthController.isLoading;
  }
  public get isError() {
    return AuthController.isErrorForget;
  }
  public get isVerified() {
    return AuthController.isverified;
  }
  public get openModalSuccess() {
    return AuthController.openModalSuccess;
  }
  public get openModalFailed() {
    return AuthController.openModalFailed;
  }
  public closeModal() {
    AuthController.setCloseModal(false);
  }

  //Error Internet or System
  get isErrorInternetOrSystem() {
    return AuthController.isErrorInternetOrSystem;
  }
  //Error Cause
  get errorCause() {
    return AuthController.errorCause;
  }

  handleErrorClose() {
    AuthController.handleError();
  }

  submitEmail() {
    if (this.form.email.length === 0) {
      this.emptyEmail = true;
      this.showError.email = false;
    } else {
      this.showError.email = false;
      this.emptyEmail = false;
      if (!this.showError.email && !this.emptyEmail) {
        this.isOpen = true;
        this.handleErrorClose();
      }
      AuthController.sendEmail(this.form);
    }
  }
  onClose() {
    this.isOpen = false;
    this.$router.push("/");
  }
  onLogin() {
    this.$router.push("/");
  }
}
